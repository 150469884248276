import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (!_ctx.isLoading)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}