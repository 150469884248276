import { createApp } from 'vue'
import { Quasar, Dialog, Meta } from 'quasar'
import { createGtm } from 'vue-gtm'
import { CommonInput, CommonTextarea, CommonRadio, CommonFilePicker, CommonInputFile } from '@/estimate/components'

import App from './app.vue'
import router from './router'

import './css/index.scss'

createApp(App)
  .component('common-input', CommonInput)
  .component('common-textarea', CommonTextarea)
  .component('common-radio', CommonRadio)
  .component('common-file-picker', CommonFilePicker)
  .component('common-input-file', CommonInputFile)
  .use(router)
  .use(
    createGtm({
      // id: 'GTM-NQMT5KZ',
      id: 'GTM-N2R6Q6W',
      debug: process.env.NODE_ENV === 'development',
      // TODO: 謎のTSエラーが出る。プラグイン側の不具合？
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      vueRouter: router,
    }),
  )
  .use(Quasar, { plugins: { Dialog, Meta } })
  .mount('#app')
