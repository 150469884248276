
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'PageLayout',
  setup() {
    const route = useRoute()
    return {
      route,
    }
  },
})
