
import { defineComponent, ref, computed, onMounted, provide } from 'vue'
import { useRoute } from 'vue-router'
import { Cookies, useMeta, Loading } from 'quasar'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import api from '@/estimate/api'
import {
  useScrapInput,
  ScrapInputKey,
  useScrapImage,
  ScrapImageKey,
  useScrapValidation,
  ScrapValidationKey,
  useScrapIncentive,
  ScrapIncentiveKey,
} from '@/estimate/composables'
import { TopLayout, CommonLayout, PageLayout } from '@/estimate/layouts'

export default defineComponent({
  name: 'App',
  setup() {
    /** ページの読み込み中か否か */
    const isLoading = ref(true)

    // 読込中スピナーを出す
    Loading.show({ spinnerColor: 'grey', backgroundColor: 'white' })

    /** フォームの入力データ */
    const scrapInput = useScrapInput()
    const { loadLocalInputData, inputData } = scrapInput
    provide(ScrapInputKey, scrapInput)

    /** フォームの画像データ */
    const scrapImage = useScrapImage()
    const { loadLocalImageData, imageData } = scrapImage
    provide(ScrapImageKey, scrapImage)

    /** フォームの検証 */
    const scrapValidation = useScrapValidation({ inputData, imageData })
    provide(ScrapValidationKey, scrapValidation)

    /** インセンティブ */
    const scrapIncentive = useScrapIncentive({ inputData, imageData })
    const { addAmount, initAddAmount } = scrapIncentive
    provide(ScrapIncentiveKey, scrapIncentive)

    // マスタデータ
    /** 車の状態 */
    const carConditionOptions = ref()
    provide('carConditionOptions', carConditionOptions)
    /** 搬入方法 */
    const carBringTakeOptions = ref()
    provide('carBringTakeOptions', carBringTakeOptions)
    /** 初年度登録年 */
    const carRegistYearOptions = ref()
    provide('carRegistYearOptions', carRegistYearOptions)
    /** 初年度登録月 */
    const carRegistMonthOptions = ref()
    provide('carRegistMonthOptions', carRegistMonthOptions)
    /** 都道府県 */
    const prefectureOptions = ref()
    provide('prefectureOptions', prefectureOptions)
    /** 最終対応ステータス */
    const responseStatusOptions = ref()
    provide('responseStatusOptions', responseStatusOptions)
    /** 連絡希望時間帯 */
    const userHopeOptions = ref()
    provide('userHopeOptions', userHopeOptions)

    // 初期化処理
    // provide を使用していると async setup() が使えないので onMounted を使う
    onMounted(async () => {
      // フィンガープリント
      // Cookieにない場合は設定する
      if (!Cookies.get('FP')) {
        const fpPromise = FingerprintJS.load()
        const fp = await fpPromise
        const result = await fp.get()
        const visitorId = result.visitorId
        Cookies.set('FP', visitorId, { path: '/' })
      }

      // ローカルに保存されたデータを読み込む
      await Promise.all([loadLocalInputData(), loadLocalImageData()])

      // 初期化処理
      await api.app.init().then(res => {
        // addAmountを設定 (車種によって後から変更される)
        Object.assign(initAddAmount, res.addAmount)
        if (inputData.carMaker === '' || inputData.carName === '') {
          Object.assign(addAmount, initAddAmount)
        }
        // オプションデータの設定
        carConditionOptions.value = res.carCondition
        carBringTakeOptions.value = res.carBringTake
        carRegistYearOptions.value = res.carRegistYear
        carRegistMonthOptions.value = res.carRegistMonth
        prefectureOptions.value = res.prefecture
        responseStatusOptions.value = res.responseStatus
        userHopeOptions.value = res.userHope
      })

      // 読込中スピナーを消す
      Loading.hide()
      // 読込中状態を解除
      isLoading.value = false
    })

    const route = useRoute()

    // レイアウト管理
    const layout = computed(() =>
      route.meta.layout === 'top'
        ? TopLayout
        : route.meta.layout == 'common'
        ? CommonLayout
        : route.meta.layout == 'page'
        ? PageLayout
        : undefined,
    )

    // メタタグ
    useMeta(() => ({
      link: {
        material: {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/icon?family=Material+Icons',
        },
        fontawesome: {
          rel: 'stylesheet',
          href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css',
        },
        lineaweosome: {
          rel: 'stylesheet',
          href: 'https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css',
        },
      },
    }))

    return { layout, isLoading }
  },
})
