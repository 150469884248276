import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/estimate/pages/index.vue'),
    meta: { layout: 'top' },
  },
  {
    path: '/record',
    name: 'Record',
    component: () => import('@/estimate/pages/record.vue'),
    meta: { layout: 'page', pageTitle: 'ご依頼いただいた買い取り実績をご紹介' },
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('@/estimate/pages/documents.vue'),
    meta: { layout: 'page' },
  },
  {
    path: '/review',
    name: 'Review',
    component: () => import('@/estimate/pages/review.vue'),
    meta: { layout: 'page', pageTitle: 'お客様の声一覧' },
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('@/estimate/pages/faq.vue'),
    meta: { layout: 'page', pageTitle: 'よくあるご質問' },
  },
  {
    path: '/form',
    name: 'Form',
    component: () => import('@/estimate/pages/form.vue'),
    meta: { layout: 'common' },
  },
  {
    path: '/confirm',
    name: 'Confirm',
    component: () => import('@/estimate/pages/confirm.vue'),
    meta: { layout: 'common' },
  },
  {
    path: '/completed',
    name: 'Completed',
    component: () => import('@/estimate/pages/completed.vue'),
    meta: { layout: 'common' },
  },
  {
    path: '/inquiry',
    name: 'Inquiry',
    component: () => import('@/estimate/pages/inquiry.vue'),
    meta: { layout: 'page', pageTitle: 'お問い合わせ' },
  },
  {
    path: '/usedcar',
    name: 'UsedCar',
    component: () => import('@/estimate/pages/usedcar.vue'),
    meta: { layout: 'page', pageTitle: '中古車に関してのお問い合わせ・見積り' },
  },
]

const router = createRouter({
  history: createWebHistory(`${process.env.BASE_URL}estimate/`),
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes,
})

export default router
